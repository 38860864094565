import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../../components/Button/Button';
import InputFile from '../../../components/InputFIle/InputFile';
import { fileToBase64 } from '../../../helpers';
import LoaderService from '../../../services/LoaderService';
import ApplicationService from '../../../services/ApplicationService';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomersService from "../../../services/CustomersService";

const ClientDocuments = ({customer_type_name, refetch, ...props }) => {

  const type = useMemo(() => {
    return customer_type_name === 'LEGAL_PERSON' ? 'STATEMENT_LEGAL_PERSON' : 'STATEMENT_OF_EARNINGS'
  }, [customer_type_name])


  const params = useParams();
  const { register, handleSubmit, watch, setValue } = useForm();

  const values = watch();

  const [addDocuments] = useMutation(
    CustomersService.postCustomerDocuments(params.id, {type: type}),
    {
      onSuccess: () => {
        toast.success('Pomyślnie przesłano dokumenty klienta.');
        setValue('documents', null);
        refetch();
      },
      onError: () => {
        toast.error('Upssss... Coś poszło nie tak.');
      },
    }
  );

  const addClientDocuments = async (values) => {
    LoaderService.show();
    const formData = new FormData();
    const file = values.documents[0];

    if (file) {
      const fileBase = await fileToBase64(file);
      formData.append('filename', file.name);
      formData.append('binaryStr', fileBase);
    }
 
     await addDocuments(formData);

    LoaderService.hide();
  };
  return (
      <form onSubmit={handleSubmit(addClientDocuments)} {...props}>
        <InputFile
            ref={register}
            name={'documents'}
            className="w-100 mb-3"
            title="Wgraj dokumenty Klienta"
            watch={watch}
        />
        {values.documents && values.documents.length > 0 && (
            <Button
                variant=""
                className="w-100 mt-1"
                type="submit"
                disabled={!values.documents || values.documents.length === 0}
            >
              Zapisz
            </Button>
        )}
      </form>
  );
};

export default ClientDocuments;
