import React, { forwardRef, useRef } from 'react';
import Button from '../Button/Button';
import styles from './InputFile.module.css';
import cs from 'classnames';

const InputFile = forwardRef(({ className = '', watch, ...props }, ref) => {
  const labelRef = useRef(null);

  const handleClick = () => {
    labelRef.current.click();
  };

  const fileName =
    (watch(props.name) && watch(props.name)[0] && watch(props.name)[0].name) ||
    '';

  return (
    <div className="d-flex flex-column gap-1">
      <Button onClick={handleClick} className={cs(className, styles.button)}>
        {props.title || 'Wybierz plik'}
      </Button>
      <label ref={labelRef} className={styles.label}>
        <input className={styles.input} type="file" ref={ref} {...props} />
      </label>
      {fileName && fileName}
    </div>
  );
});

export default InputFile;
