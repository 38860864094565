export const APPLICATION_STATUS = {
  WAITING_TO_BE_TAKEN: 'Czeka na przyjęcie',
  WAITING_FOR_RESERVATION: 'Czeka na rezerwację',
  WAITING_FOR_DECISION: 'Czeka na decyzję',
  WAITING_FOR_AGREEMENT: 'Czeka na Umowę',
  CONFIRM_AGREEMENT: 'Zatwierdź Umowę',
  COMPLETED: 'Zrealizowane',
  REJECTED: 'Wniosek odrzucony',
  BIK_ERROR: 'Błąd BIK',
  ARCHIVED: 'Zarchiwizowany',
};

export const APPLICATION_STATUS_SYMBOL = {
  WAITING_TO_BE_TAKEN: 'WAITING_TO_BE_TAKEN',
  WAITING_FOR_RESERVATION: 'WAITING_FOR_RESERVATION',
  WAITING_FOR_DECISION: 'WAITING_FOR_DECISION',
  WAITING_FOR_AGREEMENT: 'WAITING_FOR_AGREEMENT',
  CONFIRM_AGREEMENT: 'CONFIRM_AGREEMENT',
  COMPLETED: 'COMPLETED',
  REJECTED: 'REJECTED',
  BIK_ERROR: 'BIK_ERROR',
  ARCHIVED: 'ARCHIVED',
};

export const applicationsRoutes = {
  NATURAL_PERSON: 'personal',
  LEGAL_PERSON: 'company',
  INDIVIDUAL_ENTREPRENEUR: 'entrepreneur',
};

export const ORDER_STATUS = {
  CONFIRM_THE_ORDER: {
    NAME: 'CONFIRM_THE_ORDER',
    NAME_PL: 'Potwierdź zamówienie',
  },
  WAITING_FOR_THE_PROTOCOL: {
    NAME: 'WAITING_FOR_THE_PROTOCOL',
    NAME_PL: 'Wprowadź Protokół Wydania',
  },
  WAITING_FOR_THE_CONTRACT_SCAN: {
    NAME: 'WAITING_FOR_THE_CONTRACT_SCAN',
    NAME_PL: 'Wprowadź skan Umowy',
  },
  COMPLETED: {
    NAME: 'COMPLETED',
    NAME_PL: 'Zamówienie zrealizowane',
  },
  ARCHIVED: {
    NAME: 'ARCHIVED',
    NAME_PL: 'Zarchiwizowany',
  },
};

export const RESERVATION_STATUS = {
  REQUIRES_CONFIRMATION: {
    NAME: 'REQUIRES_CONFIRMATION',
    NAME_PL: 'Wymaga potwierdzenia',
  },
  ACTIVE: {
    NAME: 'ACTIVE',
    NAME_PL: 'Aktywne',
  },
  EXPIRED: {
    NAME: 'EXPIRED',
    NAME_PL: 'Wygasłe',
  },
  REJECTED: {
    NAME: 'REJECTED',
    NAME_PL: 'Odrzucone',
  },
  CUSTOMER_DECISION: {
    NAME: 'CUSTOMER_DECISION',
    NAME_PL: 'Decyzja klienta',
  },
};

export const CUSTOMER_RETURN_STATUS = {
  PENDING: {
    NAME: 'PENDING',
    NAME_PL: 'Oczekuje',
  },
  STARTED: {
    NAME: 'STARTED',
    NAME_PL: 'Rozpoczęty',
  },
  COMPLETED: {
    NAME: 'COMPLETED',
    NAME_PL: 'Zrealizowany',
  },
  CANCELED: {
    NAME: 'CANCELED',
    NAME_PL: 'Anulowany',
  },
};

export const VOUCHER_SWITCH_SYMBOL = {
  TRUE: {
    SYMBOL: 'TRUE',
    NAME: 'TRUE',
    NAME_PL: 'Aktywny',
  },
  FALSE: {
    SYMBOL: 'FALSE',
    NAME: 'FALSE',
    NAME_PL: 'Nieaktywny',
  },
  COMPLETED: {
    SYMBOL: 'COMPLETED',
    NAME: 'COMPLETED',
    NAME_PL: 'Zrealizowany',
  },
  NOT_APPLICABLE: {
    SYMBOL: 'NOT_APPLICABLE',
    NAME: 'NOT_APPLICABLE',
    NAME_PL: 'Nie dotyczy',
  },
  NOT_IMPLEMENTED: {
    SYMBOL: 'NOT_IMPLEMENTED',
    NAME: 'NOT_IMPLEMENTED',
    NAME_PL: 'Płatność bez vouchera',
  },
};

export const VERIFICATION_STATUS = {
  PENDING: {
    SYMBOL: 'PENDING',
    NAME_PL: 'Oczekuje na weryfikację',
  },
  ACCEPTED: {
    SYMBOL: 'ACCEPTED',
    NAME_PL: 'Klient zweryfikowany pozytywnie',
  },
  BIK_ERROR: {
    SYMBOL: 'BIK_ERROR',
    NAME_PL: 'Błąd BIK',
  },
  REJECTED: {
    SYMBOL: 'REJECTED',
    NAME_PL: 'Klient zweryfikowany negatywnie',
  },
  STARTED: {
    SYMBOL: 'STARTED',
    NAME_PL: 'Rozpoczęto wypełnianie formularza',
  },
  CREATED: {
    SYMBOL: 'CREATED',
    NAME_PL: 'Utworzono użytkownika. Nie rozpoczęto formularza',
  },
  EMAIL_CONFIRMED: {
    SYMBOL: 'EMAIL_CONFIRMED',
    NAME_PL: 'Użytkownik potwierdził email',
  },
};

export const AGREEMENT_STATUS = {
  ACTIVE: {
    SYMBOL: 'ACTIVE',
    NAME_PL: 'Aktywna',
  },
  FINISHED_UNSETTLED: {
    SYMBOL: 'FINISHED_UNSETTLED',
    NAME_PL: 'Zakończona i nierozliczona',
  },
  FINISHED_SETTLED: {
    SYMBOL: 'FINISHED_SETTLED',
    NAME_PL: 'Zakończona i rozliczona',
  },
  null: {
    SYMBOL: null,
    NAME_PL: 'Brak',
  },
};

export const CUSTOMER_TYPE = {
  NATURAL_PERSON: {
    SYMBOL: 'NATURAL_PERSON',
    NAME_PL: 'Osoba fizyczna',
  },
  INDIVIDUAL_ENTREPRENEUR: {
    SYMBOL: 'INDIVIDUAL_ENTREPRENEUR',
    NAME_PL: 'Działalność',
  },
  LEGAL_PERSON: {
    SYMBOL: 'LEGAL_PERSON',
    NAME_PL: 'Spółka',
  },
};

export const MARTIAL_STATUS = {
  MARRIED_MALE: {
    SYMBOL: 'MARRIED_MALE',
    NAME_PL: 'żonaty',
  },
  MARRIED_FEMALE: {
    SYMBOL: 'MARRIED_FEMALE',
    NAME_PL: 'mężatka',
  },
  OTHER: {
    SYMBOL: 'OTHER',
    NAME_PL: 'inny',
  },
};
