import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import CustomersService from '../../../services/CustomersService';
import { CInput, CSwitch } from '@coreui/react';
import { Controller, useForm } from 'react-hook-form';
import DataRow from '../../../components/DataRow/DataRow';
import Button from '../../../components/Button/Button';
import Loader from '../../../components/Loader/Loader';
import { toast } from 'react-toastify';
import { removeEmptyStringsAndNulls } from '../../../helpers';

const IncomeAnalysisLP = ({ customerId, income_analysis, status, refetch }) => {
  const [updateIncomeCalculatorLP, { isLoading }] = useMutation(
    CustomersService.updateIncomeCalculatorLP(customerId),
    {
      onSuccess: () => {
        toast.success('Pomyślnie zaktualizowano dane.');
        refetch();
      },
      onError: (error) => {
        const isArrError = !!error?.response?.data?.errors;
        const isSingleError = !!error?.response?.data?.data;

        if (isArrError) {
          toast.error('Ups... Coś poszło nie tak...');
          const errors = error.response.data.errors;
          errors.forEach(({ invalid_property, message }) => {
            setError(invalid_property, {
              type: 'manual',
              message: message,
            });
          });
        }
        if (isSingleError) {
          toast.error(
            error?.response?.data?.data || 'Ups... Coś poszło nie tak...'
          );
        }
      },
    }
  );

  const { register, handleSubmit, reset, watch, control, errors, setError } =
    useForm({
      defaultValues: {
        assets: '',
        current_liabilities_total: '',
        current_liabilities_credits: '',
        current_liabilities_other: '',
        long_term_liabilities_credits: '',
        long_term_liabilities_other: '',
        cash_and_other_assets: '',
        depreciation: '',
        profit_loss_operational_activities: '',
        equity_capital: '',
        balance_sheet_total: '',
        net_income: '',
        profit_loss_sale: '',
        net_profit_loss: '',
        no_data: true,
      },
    });

  const noDataWatcher = watch('no_data');

  useEffect(() => {
    reset(income_analysis);
  }, [income_analysis, reset]);

  const onSubmit = (values) => {
    updateIncomeCalculatorLP(removeEmptyStringsAndNulls(values));
  };

  const isEditable = status !== 'ACCEPTED' && status !== 'REJECTED';

  const replaceDotsWithComas = (input) => {
    if (
      input.currentTarget.value !== null &&
      input.currentTarget.value.length > 1
    ) {
      input.target.value = input.currentTarget.value.replace(/\./g, ',');
    }
  };
  return (
    <>
      {isLoading && <Loader show />}

      <form onSubmit={handleSubmit(onSubmit)}>
        <DataRow
          label={'Brak danych finansowych'}
          value={
            <Controller
              control={control}
              name="no_data"
              render={({ value, onChange }) => (
                <CSwitch
                  checked={value}
                  onChange={(e) => onChange(e.target.checked)}
                  labelOn="tak"
                  labelOff="nie"
                  shape="pill"
                  color="success"
                  disabled={!isEditable}
                />
              )}
            />
          }
        />
        {!noDataWatcher && (
          <>
            <DataRow
              label="Aktywa obrotowe ogółem"
              InvalidInput={errors?.assets?.message}
              value={
                isEditable ? (
                  <CInput
                    name="assets"
                    innerRef={register}
                    onBlur={replaceDotsWithComas}
                  />
                ) : (
                  income_analysis.assets
                )
              }
            />
            <DataRow
              label="Zobowiązania krótkoterminowe ogółem"
              InvalidInput={errors?.current_liabilities_total?.message}
              value={
                isEditable ? (
                  <CInput
                    name={'current_liabilities_total'}
                    ref={register}
                    onBlur={replaceDotsWithComas}
                  />
                ) : (
                  income_analysis.current_liabilities_total
                )
              }
            />
            <DataRow
              label="Zobowiązania krótkoterminowe z tytułu kredytów i pożyczek"
              InvalidInput={errors?.current_liabilities_credits?.message}
              value={
                isEditable ? (
                  <CInput
                    name={'current_liabilities_credits'}
                    innerRef={register}
                    onBlur={replaceDotsWithComas}
                  />
                ) : (
                  income_analysis.current_liabilities_credits
                )
              }
            />
            <DataRow
              label="Zobowiązania krótkoterminowe - inne finansowe"
              InvalidInput={errors?.current_liabilities_other?.message}
              value={
                isEditable ? (
                  <CInput
                    name={'current_liabilities_other'}
                    innerRef={register}
                    onBlur={replaceDotsWithComas}
                  />
                ) : (
                  income_analysis.current_liabilities_other
                )
              }
            />
            <DataRow
              label="Zobowiązania długoterminowe z tytułu kredytów i pożyczek"
              InvalidInput={errors?.long_term_liabilities_credits?.message}
              value={
                isEditable ? (
                  <CInput
                    name={'long_term_liabilities_credits'}
                    innerRef={register}
                    onBlur={replaceDotsWithComas}
                  />
                ) : (
                  income_analysis.long_term_liabilities_credits
                )
              }
            />
            <DataRow
              label="Zobowiązania długoterminowe - inne finansowe"
              InvalidInput={errors?.long_term_liabilities_other?.message}
              value={
                isEditable ? (
                  <CInput
                    name={'long_term_liabilities_other'}
                    innerRef={register}
                    onBlur={replaceDotsWithComas}
                  />
                ) : (
                  income_analysis.long_term_liabilities_other
                )
              }
            />
            <DataRow
              label="Środki pieniężne i inne aktywa pieniężne"
              InvalidInput={errors?.cash_and_other_assets?.message}
              value={
                isEditable ? (
                  <CInput
                    name={'cash_and_other_assets'}
                    innerRef={register}
                    onBlur={replaceDotsWithComas}
                  />
                ) : (
                  income_analysis.cash_and_other_assets
                )
              }
            />
            <DataRow
              label="Kapitały własne"
              InvalidInput={errors?.depreciation?.message}
              value={
                isEditable ? (
                  <CInput
                    name={'depreciation'}
                    innerRef={register}
                    onBlur={replaceDotsWithComas}
                  />
                ) : (
                  income_analysis.depreciation
                )
              }
            />
            <DataRow
              label="Zysk / strata na działalności operacyjnej"
              InvalidInput={errors?.profit_loss_operational_activities?.message}
              value={
                isEditable ? (
                  <CInput
                    name={'profit_loss_operational_activities'}
                    innerRef={register}
                    onBlur={replaceDotsWithComas}
                  />
                ) : (
                  income_analysis.profit_loss_operational_activities
                )
              }
            />
            <DataRow
              label="Suma bilansowa"
              InvalidInput={errors?.balance_sheet_total?.message}
              value={
                isEditable ? (
                  <CInput
                    name={'balance_sheet_total'}
                    innerRef={register}
                    onBlur={replaceDotsWithComas}
                  />
                ) : (
                  income_analysis.balance_sheet_total
                )
              }
            />
            <DataRow
              label="Amortyzacja"
              InvalidInput={errors?.depreciation?.message}
              value={
                isEditable ? (
                  <CInput
                    name={'depreciation'}
                    innerRef={register}
                    onBlur={replaceDotsWithComas}
                  />
                ) : (
                  income_analysis.depreciation
                )
              }
            />
            <DataRow
              label="Przychody netto"
              InvalidInput={errors?.net_income?.message}
              value={
                isEditable ? (
                  <CInput
                    name={'net_income'}
                    innerRef={register}
                    onBlur={replaceDotsWithComas}
                  />
                ) : (
                  income_analysis.net_income
                )
              }
            />
            <DataRow
              label="Zysk / strata na sprzedaży"
              InvalidInput={errors?.profit_loss_sale?.message}
              value={
                isEditable ? (
                  <CInput
                    name={'profit_loss_sale'}
                    innerRef={register}
                    onBlur={replaceDotsWithComas}
                  />
                ) : (
                  income_analysis.profit_loss_sale
                )
              }
            />
            <DataRow
              label="Zysk / strata netto"
              InvalidInput={errors?.net_profit_loss?.message}
              value={
                isEditable ? (
                  <CInput
                    name={'net_profit_loss'}
                    innerRef={register}
                    onBlur={replaceDotsWithComas}
                  />
                ) : (
                  income_analysis.net_profit_loss
                )
              }
            />
          </>
        )}
        {isEditable && (
          <Button className="w-100 mt-2" type="submit">
            Zapisz
          </Button>
        )}
      </form>
    </>
  );
};

export default IncomeAnalysisLP;
