import axios from '../axios';
import { parseObjectToUrlQuery } from '../helpers';

const CustomersService = {
  getNaturalPersonData: async (_, params) => {
    const parsedParams = params ? `?${parseObjectToUrlQuery(params)}` : '';
    return await axios
      .get(`/panel/admin/customers/natural-person${parsedParams}`)
      .then((res) => res.data);
  },
  getSingleNatruralPerson: (id) => async () => {
    return await axios
      .get(`/panel/admin/customer/natural-person/${id}`)
      .then((res) => res.data);
  },
  getLegalPersonData: async (_, params) => {
    const parsedParams = params ? `?${parseObjectToUrlQuery(params)}` : '';
    return await axios
      .get(`/panel/admin/customers/legal-person${parsedParams}`)
      .then((res) => res.data);
  },
  getSingleLegalPerson: (id) => async () => {
    return await axios
      .get(`/panel/admin/customer/legal-person/${id}`)
      .then((res) => res.data);
  },
  updateCustomer: (customerId) => (data) => {
    return axios
      .put(`/panel/admin/customer/${customerId}`, data)
      .then((res) => res.data);
  },
  getVerifiedCustomers: async (_, params) => {
    const config = params.csv ? { responseType: 'blob' } : null;
    const parsedParams = params ? `?${parseObjectToUrlQuery(params)}` : '';
    return axios
      .get(`/panel/admin/customers/verified${parsedParams}`, config)
      .then((res) => res.data);
  },
  getUnverifiedCustomers: async (_, params) => {
    const config = params.csv ? { responseType: 'blob' } : null;
    const parsedParams = params ? `?${parseObjectToUrlQuery(params)}` : '';
    return axios
      .get(`/panel/admin/customers/unverified${parsedParams}`, config)
      .then((res) => res.data);
  },
  updateIncomeCalculatorNP: (userId) => async (data) => {
    return axios
      .post(
        `panel/admin/application-customer/natural-person/params/user/${userId}`,
        data
      )
      .then((res) => res.data);
  },
  updateIncomeCalculatorIE: (userId) => async (data) => {
    return axios
      .post(
        `panel/admin/application-customer/individual-entrepreneur/params/user/${userId}`,
        data
      )
      .then((res) => res.data);
  },
  updateIncomeCalculatorLP: (userId) => async (data) => {
    return axios
      .post(
        `panel/admin/application-customer/legal-person/params/user/${userId}`,
        data
      )
      .then((res) => res.data);
  },
  acceptCustomerVerification: (userId) => async (data) => {
    return axios
      .post(`/panel/admin/application-customer/accept/user/${userId}`, data)
      .then((res) => res.data);
  },
  rejectCustomerVerification: (userId) => async (data) => {
    return axios
      .post(`/panel/admin/application-customer/reject/user/${userId}`, data)
      .then((res) => res.data);
  },
  postCustomerDocuments: (userId, params) => async (data) => {
    const parsedParams = params ? `?${parseObjectToUrlQuery(params)}` : '';
    return axios
        .post(
            `/panel/admin/statement/account-verification/customer/${userId}${parsedParams}`,
            data
        )
        .then((res) => res.data);
  }
};

export default CustomersService;
