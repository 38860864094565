import { CCard, CCol, CProgress, CRow } from '@coreui/react';
import cs from 'classnames';
import React from 'react';
import classes from '../Application.module.css';

const ScoringResults = ({
  scoring_summary: { scoring, total_points, total_points_max },
}) => (
  <CCard
    className={cs(
      'px-4 shadow-none h-100 d-flex justify-content-center',
      classes.scoringContainer
    )}
  >
    <CRow>
      <CCol sm="12" xl="12" className={classes.scoringTitle}>
        {total_points} PKT {scoring && `/ ${scoring}%`}
      </CCol>
    </CRow>
    {scoring && (
      <>
        <CRow>
          <CCol sm="12" xl="12" className={classes.scoringSubtitle}>
            Wynik Scoringu
          </CCol>
        </CRow>

        <CRow>
          <CCol sm="12" xl="12">
            <CProgress
              color="white"
              size="sm"
              className={classes.progressbar}
              value={scoring}
            />
          </CCol>
        </CRow>
      </>
    )}
    <CRow>
      <CCol sm="12" xl="12" className={classes.scoringDescription}>
        Maksymalna liczba punktów scoringowych możliwa do uzyskania dla tego
        podmiotu to {total_points_max}
      </CCol>
    </CRow>
  </CCard>
);

export default ScoringResults;
